<template>
    <div class="row">
      <div class="col-lg-12">
        <b-overlay :show="loading" rounded="sm">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{isEdit ? $t('update') : $t('register')}} {{$t('customer')}}
                <button :disabled="lock_deleteBtn" v-show="isEdit && !lock_deleteBtn" class="btn btn-outline-danger btn-sm ms-1" @click="deleteUser">DELETE</button>
              </h4>

              <div class="row">
                <div class="col-lg-8">
                    <form>
                      <Customer-basic :customer-id="customer_id" :temp-register="false" @BasicInfoChanged="onChildUpdate($event)" />

                      <b-form-group
                        label="Remark / 备忘录"
                        label-for="form-customer-remark"
                        class="mb-3"
                      >
                      <textarea id="form-customer-remark" v-model="remark" class="form-control" rows="3" placeholder=""></textarea>
                      </b-form-group>

                      <div class="row">
                        <div class="col-md-4">
                          <b-form-group
                            label="Last Update / 最后更新"
                            label-for="form-customer-last-update"
                            class="mb-3"
                          >
                            <b-form-input
                              id="form-customer-last-update"
                              type="text"
                              readonly
                              v-model="last_update"
                            ></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-4" v-show="isEdit">
                          <b-form-group
                            label="Status / 状态"
                            label-for="form-customer-status"
                            class="mb-3"
                          >
                            <select label-for="form-customer-status" class="form-select" v-model="status">
                              <option value="ACTIVE">ACTIVE</option>
                              <option value="INACTIVE">INACTIVE</option>
                              <option value="DELETED">DELETED</option>
                            </select>
                          </b-form-group>
                        </div>
                      </div>                                  
                    </form>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card" style="border: 1px solid #f6f6f6">
                      <div class="card-body table-responsive">
                        <Customer-contact @updatedParent="onContactUpdate($event)" :customer-id="customer_id" :is-edit="isEdit" />
                      </div>
                      <!-- end card-body -->
                    </div>
                    <!-- end card -->
                  </div>
                  <!-- end col -->
                </div>
              </div>

              <button class="btn btn-primary" @click="save" :disabled="lock_createBtn" v-show="!lock_createBtn">
                {{isEdit ? 'Update' : 'Create'}} <i class="far fa-save p-1"></i>
              </button>

            </div>
          </div>
        </b-overlay>
      </div>
    </div>
</template>

<script>
  import CustomerBasic from "./CustomerBasic"
  import CustomerContact from "./CustomerContact"
  import moment from 'moment'
  import { authFackMethods } from "@/state/helpers";

  export default {
    name: 'CustomerDetail',
    components: { CustomerBasic, CustomerContact },
    props: {
      isEdit: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        customer_id: '0',
        basic_info: {
          name: '',
          nric: '',
          dob: '',
          contact: '',
          address: '',
          working: '',
        },
        remark: '',
        last_update: '',
        status: '',
        contact_lists: [],
        loading: false,
      }
    },
    computed:{
      compute_user(){
        return this.$store.state.authfack.user
      },
      lock_createBtn(){
        if(this.compute_user.permissions.includes('*') || this.compute_user.permissions.includes('031')){
          return false
        }
        else{
          return true
        }
      },
      lock_deleteBtn(){
        if(this.compute_user.permissions.includes('*') || this.compute_user.permissions.includes('033')){
          return false
        }
        else{
          return true
        }
      },
    },
    created(){
      if (this.isEdit) {
        const id = this.$route.params && this.$route.params.id
        this.customer_id = id;
        this.loadCustomerInfo()
      }
    },
    methods: {
      ...authFackMethods,
      onChildUpdate (obj) {
        this.basic_info = {
          name: obj.name,
          nric: obj.nric,
          dob: obj.dob,
          contact: obj.contact,
          address: obj.address,
          working: obj.working
        }
      },
      onContactUpdate (arr_list) {
        this.contact_lists = arr_list
      },
      loadCustomerInfo(){
        let self = this
        self.loading = true
        const baseURI = 'customers/'+self.customer_id
      
        self.$http.get(baseURI)
        .then((result) => {
          if(result.data.status == 'success'){
            let customer = result.data.data
            self.basic_info = {
              name: customer.name,
              nric: customer.idno,
              dob: customer.dob,
              contact: customer.contact,
              address: customer.address,
              working: customer.career
            }
            self.remark = customer.remark
            self.last_update = moment(customer.updated_at).format('YYYY-MM-DD HH:mm')
            self.status = customer.status

            self.loading = false
          }
        })
        .catch((err) => {
          if(err == 'session_timeout'){
            self.$notify({
              group: 'auth',
              title: 'User Authentication',
              text: 'Your session has expired, please login again',
              type: 'warn'
            });
            self.logout();
            self.$router.push({
              path: "/login",
            });
          }
        })
      },
      deleteUser(){
        let self = this

        var r = confirm('Delete this customer?');
        if (r == true) {
          self.loading = true
          // https://www.maxwell777.com/dev/api/v1/
          const baseURI = 'customers/'+self.customer_id
          self.$http.delete(baseURI)
          .then((result) => {
            // console.log(result)
            if(result.data.status == 'success'){
              self.loading = false
              self.$notify({
                group: 'app',
                title: `Customer deleted`,
                type: 'success'
              });
              self.$router.push({
                path: "/customer/list",
              });
            }
          })
          .catch((err) => {
            if(err == 'session_timeout'){
              self.logout();
              self.$router.push({
                path: "/login",
              });
            }
          })
        }
      },
      save(){
        let self = this
        // console.log('basic_info ',self.basic_info);
        // console.log('remark ',self.remark);
        // console.log('contact_lists ',JSON.stringify(self.contact_lists) );
        self.loading = true

        if (self.isEdit && self.customer_id !== '0') {
          //update
          const baseURI = 'customers/'+self.customer_id
          let formData = new FormData();
          formData.append('_method', 'PUT' )
          formData.append('name', self.basic_info.name )
          formData.append('idno', self.basic_info.nric )
          formData.append('dob', self.basic_info.dob )
          formData.append('contact', self.basic_info.contact )
          formData.append('address', self.basic_info.address )
          formData.append('career', self.basic_info.working )
          formData.append('remark', self.remark )
          formData.append('status', self.status )
          formData.append('dependents', JSON.stringify(self.contact_lists) )

          self.$http.post(baseURI,formData)
          .then((result) => {
            if(result.data.status == 'success'){
              self.loading = false
              self.$notify({
                group: 'app',
                title: 'Update customer successful',
                type: 'success'
              });
              self.loadCustomerInfo()
            }
          })
          .catch((err) => {
            if(err == 'session_timeout'){
              self.$notify({
                group: 'auth',
                title: 'User Authentication',
                text: 'Your session has expired, please login again',
                type: 'warn'
              });
              self.logout();
              self.$router.push({
                path: "/login",
              });
            }
          })
        }
        else{
          //create
          const baseURI = 'customers'
          let formData = new FormData();

          formData.append('name', self.basic_info.name )
          formData.append('idno', self.basic_info.nric )
          formData.append('dob', self.basic_info.dob )
          formData.append('contact', self.basic_info.contact )
          formData.append('address', self.basic_info.address )
          formData.append('career', self.basic_info.working )
          formData.append('remark', self.remark )
          formData.append('status', 'ACTIVE')
          formData.append('dependents', JSON.stringify(self.contact_lists) )

          self.$http.post(baseURI,formData)
          .then((result) => {
            if(result.data.status == 'success'){
              self.loading = false
              self.$notify({
                group: 'app',
                title: 'Register customer successful',
                type: 'success'
              });
              self.$router.push({
                path: "/customer/list",
              });
            }
          })
          .catch((err) => {
            if(err == 'session_timeout'){
              self.$notify({
                group: 'auth',
                title: 'User Authentication',
                text: 'Your session has expired, please login again',
                type: 'warn'
              });
              self.logout();
              self.$router.push({
                path: "/login",
              });
            }
          })
        }

      }
    }
  }
  </script>